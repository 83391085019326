const DARK_BLUE_COLOR_VALUES = '35, 41, 104'

export const WHITE_COLOR = '#ffffff'
export const LIGHT_GREY_COLOR = '#F2F2F2'
export const LIGHTEST_GREY_COLOR = '#F7FAFF'
export const GHOST_WHITE_COLOR = '#FAFBFF'
export const DARK_GHOST_WHITE_COLOR = '#F4F4F7'
export const BLACK_COLOR = '#000000'
export const LIGHT_BLUE_COLOR = '#2855FF'
export const LIGHT_BLUE_COLOR_10 = 'rgba(40, 85, 255, 0.1)'
export const GRAY_COLOR = '#D6D8E7'
export const LIGHT_GRAY_COLOR = '#394352'
export const DARK_GRAY_COLOR = '#262A35'
export const LIGHTEN_GRAY_COLOR = '#F4F7FF'
export const LIGHTEN_GRAY_COLOR_50 = 'rgba(244, 247, 255, 0.5)' // #f4f7ff80
export const LIGHTEST_GRAY_COLOR = '#E3E4EE'
export const DARKEN_GRAY_COLOR = '#B8BBD4'
export const PALE_GRAY_COLOR = '#F6F9FF'
export const LIGHT_GREEN_COLOR = '#E6F9F2'
export const DARK_GREEN_COLOR = '#07C07E'
export const DARK_GREEN_COLOR_10 = 'rgba(7, 192, 126, 0.1)' // #07C07E1A
export const DARK_GREEN_DISABLED_COLOR = '#C0EAD9'
export const LIGHT_RED_COLOR = '#FCEDEC'
export const DARK_RED_COLOR = '#E54C40'
export const DARK_RED_COLOR_20 = 'rgba(229, 76, 64, 0.20)'
export const DARK_RED_ERROR_COLOR = '#EA3D2F'
export const ORANGE_COLOR = '#F29D41'
export const LIGHT_ORANGE_COLOR = '#FFEBB4'
export const LIGHT_ORANGE_COLOR_50 = 'rgba(255, 235, 180, 0.50)'
export const BLACK_BLUE_COLOR_50 = 'rgba(3, 0, 71, 0.50)'
export const DARK_BLUE_COLOR = '#232968'
export const DARK_BLUE_COLOR_05 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.05)` // #2329680d
export const DARK_BLUE_COLOR_10 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.1)` // #2329681a
export const DARK_BLUE_COLOR_30 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.3)` // #2329684d
export const DARK_BLUE_COLOR_40 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.4)` // #23296866
export const DARK_BLUE_COLOR_50 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.5)` // #23296880
export const DARK_BLUE_COLOR_60 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.6)` // #23296899
export const DARK_BLUE_COLOR_70 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.7)` // #232968b3
export const DARK_BLUE_COLOR_80 = `rgba(${DARK_BLUE_COLOR_VALUES}, 0.8)` // #232968cc
export const YELLOW_INFO_RELEASE_COLOR = '#FEF7F0'
export const GREEN_INFO_APPROVE_COLOR = '#E7F9F3'
export const RED_INFO_REJECTED_COLOR = '#FDEEEC'
export const LIGHTEST_BLUE_COLOR = '#F9FBFF'
export const PURPLE_COLOR = '#7109D1'
export const PALE_PURPLE_COLOR = '#6254B9'
export const LIGHT_PURPLE_COLOR = '#8B33D0'
export const DARK_ORANGE_COLOR = '#da6920'
export const ALICE_BLUE_COLOR = '#EDF2FF'
export const MAASTRICHT_BLUE_COLOR = '#301508'
export const SWITCH_LIGHT_BLUE = '#CBD6FF'
export const SWITCH_DARK_GRAY = '#9194B3'
export const WHITE_GRAY_COLOR = '#F8F9FA'
export const DARK_WHITE_GRAY_COLOR = '#D0D3E3'
export const LIGHTEN_BLUE_COLOR = '#F2F6FE'
export const GRAY_SILVER_COLOR = '#F2F2F6'
export const DARK_GRAY_SILVER_COLOR = '#656995'
export const DARK_GRAY_SILVER_COLOR_15 = 'rgba(101, 105, 149, 0.15)' // #65699526
export const GRAY_WHITE_COLOR = '#FBFBFC'
export const LIGHT_OCEAN_BLUE_COLOR = '#D4DDFF'
export const LIGHT_SILVER_BLUE_COLOR = '#E3E4ED'
export const LIGHT_BLUE_DARKER_DISABLED_COLOR = 'rgba(40, 85, 255, 0.5)' // #2855ff80
export const LIGHT_GREEN_COLOR_RGB = '#E6F9F2'
export const LAVENDER_COLOR = '#EAEEFF'
export const FIREBRICK_COLOR = '#C71919'
export const MID_BLUE_COLOR = '#DFE6FF'
export const LIGHT_BLUE_DISABLED_COLOR = '#E5EAFF'
export const DARK_RED_DISABLED_COLOR = '#F5B7B3'
export const BLUE_VIOLET_COLOR = 'rgba(103, 58, 183, 0.05)' // #673ab70d

export const SIDEBAR_BACKGROUND_COLOR = WHITE_COLOR
export const SIDENAV_BORDER_COLOR = GRAY_COLOR
export const CARD_BORDER_COLOR = '#DEDFE8'

export const TEXTFIELD_COLOR = DARK_BLUE_COLOR
export const ERROR_COLOR = '#f44336'
export const SELECTED_COLOR = LIGHT_BLUE_COLOR_10
export const HOVERED_GRAY_COLOR = '#F5F5F5'
export const SHADOW_GRAY_COLOR = 'rgba(8, 35, 48, 0.12)' // #0823301f
