import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import FormControlTheme from 'themes/FormControlTheme'
import ErrorBoundary from 'errorBoundary/ErrorBoundary'
import LoadingWithLogo from 'components/shared/loading/LoadingWithLogo'
import HelpCrunch from 'components/shared/helpCrunch/HelpCrunch'
import { waitForLoad } from 'helpers/common/waitForLoad'
import reportWebVitals from './reportWebVitals'
import Root from './Root'

import('./index.css')
import('./helpers/reactNativeUtils')

if (process.env.NODE_ENV !== 'development') {
  waitForLoad(
    () => false,
    () => {
      const sentry = import('@sentry/react')
      const integrations = import('@sentry/tracing')

      Promise.all([sentry, integrations]).then((modules) => {
        const [Sentry, _integrations] = modules
        const { Integrations } = _integrations

        Sentry.init({
          dsn: process.env.REACT_APP_SENTRY_DSN,
          autoSessionTracking: true,
          integrations: [new Integrations.BrowserTracing()],

          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0,
          // this is for tracking all environments in sentry
          environment: process.env.REACT_APP_ENVIRONMENT,
          normalizeDepth: 10,
        })
      })
      import('react-hotjar').then(({ hotjar }) => {
        hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6)
      })
    }
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Suspense fallback={<LoadingWithLogo />}>
        <Router>
          <ThemeProvider theme={FormControlTheme}>
            <Root />
          </ThemeProvider>
        </Router>
      </Suspense>
      <HelpCrunch />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
