export const HOME_SCREEN_ROUTE = '/'
export const PRICING_SCREEN_ROUTE = '/pricing'
export const HOW_IT_WORKS_SCREEN_ROUTE = '/how-it-works'
export const TEAM_MANAGEMENT_SCREEN_ROUTE = '/team-management'
export const PROJECT_MANAGEMENT_SCREEN_ROUTE = '/construction-project-management-software'
export const PRE_CONSTRUCTION_SCREEN_ROUTE = '/preconstruction-software'
export const FINANCIAL_MANAGEMENT_SCREEN_ROUTE = '/construction-financial-management-software'
export const RFI_TOOLS_SCREEN_ROUTE = '/rfi'
export const ESTIMATION_SCREEN_ROUTE = '/estimation'
export const PROPOSAL_SCREEN_ROUTE = '/proposal'
export const SCHEDULING_SCREEN_ROUTE = '/scheduling'
export const BILLS_SCREEN_ROUTE = '/bills'
export const TAKEOFF_SCREEN_ROUTE = '/construction-takeoff-software'
export const CLIENT_PORTAL_SCREEN_ROUTE = '/construction-client-portal'
export const TIMESHEET_SCREEN_ROUTE = '/construction-timesheets-software'
export const SELECTIONS_SCREEN_ROUTE = '/construction-client-selections-software'
export const ACCOUNTING_SCREEN_ROUTE = '/construction-accounting-software'
export const MOBILE_APP_SCREEN_ROUTE = '/construction-mobile-app'
export const BUDGET_SCREEN_ROUTE = '/construction-budgeting-software'
export const COST_CATALOG_SCREEN_ROUTE = '/construction-cost-catalog'
export const CHANGE_ORDER_SCREEN_ROUTE = '/construction-change-orders-software'
export const DAILY_LOG_SCREEN_ROUTE = '/construction-daily-logs-software'
export const TO_DO_SCREEN_ROUTE = '/construction-to-do-list-software'
export const PURCHASE_ORDER_SCREEN_ROUTE = '/construction-purchase-orders-software'
export const PRICE_LIST_SCREEN_ROUTE = '/construction-price-list-software'
export const WHATS_NEW_SCREEN_ROUTE = '/whats-new'
export const UPCOMING_FEATURES_SCREEN_ROUTE = '/upcoming-features'
export const DOCUMENT_MANAGEMENT_SCREEN_ROUTE = '/document-management'
export const CRM_SCREEN_ROUTE = '/construction-crm-software'
export const INVOICES_SCREEN_ROUTE = '/invoices'
export const BIDDING_SCREEN_ROUTE = '/bidding'
export const ABOUT_US_SCREEN_ROUTE = '/about-us'
export const CONTACT_US_SCREEN_ROUTE = '/contact-us'
export const TERMS_AND_CONDITIONS_SCREEN_ROUTE = '/terms-and-conditions'
export const PRIVACY_POLICY_SCREEN_ROUTE = '/privacy-policy'
export const COOKIE_POLICY_SCREEN_ROUTE = '/cookie-policy'
export const LANDING_CRM_SCREEN_ROUTE = '/landing-crm'
export const SIGN_IN_ROUTE = '/signin'
export const SIGN_UP_ROUTE = '/signup'
export const REGISTER_USER_ROUTE = '/register-user'
export const CONFIRM_EMAIL_ROUTE = '/confirm-email'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const CHANGE_PASSWORD_ROUTE = '/change-password'
export const JOIN_TO_COMPANY_ROUTE = '/join-to-company'
export const ONBOARDING_ROUTE = '/onboarding'
export const SETUP_COMPANY_SETTINGS_ROUTE = '/setup-company-settings'
export const COMPANY_TYPE_ROUTE = '/choose-type'
export const CREATE_PROJECT_ROUTE = '/create-project'
export const CHOOSE_DEMO_OR_SCRATCH_ROUTE = '/demo-or-scratch'
export const SYNC_WITH_XERO_ROUTE = '/sync-with-xero'
export const SYNC_COST_CODE_ROUTE = '/cost-code'
export const CONFIGURE_XERO_ROUTE = '/configure'
export const TAX_ROUTE = '/tax'
export const PRICING_ROUTE = '/choose-pricing'
export const PROJECTS_ROUTE = '/projects'
export const TIMELINE_ROUTE = '/timeline'
export const ESTIMATE_ROUTE = '/estimate'
export const ESTIMATE_ASSEMBLY_ROUTE = '/estimate/assembly'
export const PURCHASE_ORDER_ROUTE = '/purchaseorder'
export const BUDGET_ROUTE = '/budget'
export const BUDGET_LIST_ROUTE = '/list'
export const BUDGET_COST_CODE_ROUTE = '/cost-code'
export const SCHEDULED_ITEMS_ROUTE = '/scheduled-items'
export const CHANGE_ORDER_ROUTE = '/changeorder'
export const CLIENT_INVOICE_ROUTE = '/client-invoice'
export const SCHEDULE_ROUTE = '/schedule'
export const TODO_ROUTE = '/todo'
export const BILLING_ROUTE = '/billing'
export const BID_REQUEST_ROUTE = '/bid-requests'
export const BID_MANUAL_FILL_ROUTE = '/bid-manual-fill'
export const BID_ROUTE = '/bids'
export const PROJECT_OVERVIEW_ROUTE = '/overview'
export const TEAM_ROUTE = '/team'
export const DAILY_LOG_ROUTE = '/daily-logs'
export const COST_CODE_ROUTE = '/costcodes'
export const COST_CATALOG_ROUTE = '/costcatalog'
export const PRICE_LIST_ROUTE = '/pricelist'
export const FILE_MANAGEMENT_ROUTE = '/files'
export const COMPANY_SETTINGS_ROUTE = '/company-settings'
export const FINANCE_SETTINGS_ROUTE = '/finance-settings'
export const GENERAL_SETTINGS_ROUTE = '/general-settings'
export const CLIENT_PORTAL_WIDGET_ROUTE = '/client-portal-widget'
export const SYSTEM_CONFIG_ROUTE = '/system-configuration'
export const CONTRACT_TEMPLATES_ROUTE = '/contract-templates'
export const PAYMENT_SCHEDULE_ROUTE = '/payment-schedule'
export const ACCOUNTING_SETTINGS_ROUTE = '/accounting-settings'
export const FIELDS_SETTINGS_ROUTE = '/fields-settings'
export const AUTOMATED_FOLLOW_UP_SETTINGS_ROUTE = '/follow-up'
export const PROPOSALS_SETTINGS_ROUTE = '/proposal-settings'
export const USER_MANAGEMENT_ROUTE = '/user-management'
export const SUBSCRIPTION_AND_BILLING = '/subscription'
export const SUB_VENDOR_ROUTE = '/sub-vendor'
export const ROLES_AND_PERMISSIONS_ROUTE = '/roles-and-permissions'
export const CLIENTS_ROUTE = '/clients'
export const PROPOSALS_ROUTE = '/proposals'
export const RELEASED_CHANGE_ORDER_WEB_VIEW = '/released-change-order'
export const RELEASED_PURCHASE_ORDER_WEB_VIEW = '/released-purchase-order'
export const CLIENT_INVOICE_WEB_VIEW_ROUTE = '/sent-client-invoice'
export const LICENSE_AND_INSURANCE = '/license-and-insurance'
export const SENT_BID_WEB_VIEW_ROUTE = '/sent-bid'
export const SCHEDULE_DEMO_ROUTE = '/schedule-demo'
export const PROPOSAL_WEB_VIEW = '/proposal-web-view'
export const SCHEDULE_SETTINGS_ROUTE = '/schedule-settings'
export const SELECTIONS_ROUTE = '/selections'
export const OPTIONS_ROUTE = '/options'
export const SELECTION_WEB_VIEW_ROUTE = '/selection-web-view'
export const ALLOWANCES_ROUTE = '/allowances'
export const TEMPLATES_ROUTE = '/templates'
export const RESPONSES_ROUTE = '/responses'
export const COMPARE_ROUTE = '/compare'
export const TAKEOFF_ROUTE = '/takeoff'
export const TAKEOFF_LEGENDS_EXPORT_ROUTE = '/takeoff-legends-export'
export const TIMESHEETS_ROUTE = '/timesheets'
export const TIME_LOG_TIMELINE_ROUTE = '/timeline'
export const TIME_LOG_MAP_ROUTE = '/map'
export const ASSEMBLY_ROUTE = '/assembly'
export const TIMESHEETS_BASE_ROUTE = `${PROJECTS_ROUTE}/:projectId${TIMESHEETS_ROUTE}`
export const BILLS_BASE_ROUTE = `${PROJECTS_ROUTE}/:projectId${BILLING_ROUTE}`
export const CALENDAR_ROUTE = '/calendar'
export const LIST_ROUTE = '/list'
export const ALL_ITEMS_ROUTE = '/all-items'
export const BILL_EXPORT_ROUTE = '/bill-export'

// available only for wordpress side in prod mode
export const LANDING_RESOURCES_ROUTE = '/resources'
export const LANDING_RESOURCES_BLOG_ROUTE = `${LANDING_RESOURCES_ROUTE}/blog`
export const LANDING_RESOURCES_NEWS_ROUTE = `${LANDING_RESOURCES_ROUTE}/news`

export const UNAUTHENTICATED_ROUTES = [
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  JOIN_TO_COMPANY_ROUTE,
  LANDING_CRM_SCREEN_ROUTE,
  CONFIRM_EMAIL_ROUTE,
]

export const WEB_VIEW_ROUTES = [
  RELEASED_CHANGE_ORDER_WEB_VIEW,
  RELEASED_PURCHASE_ORDER_WEB_VIEW,
  SENT_BID_WEB_VIEW_ROUTE,
  CLIENT_INVOICE_WEB_VIEW_ROUTE,
  PROPOSAL_WEB_VIEW,
  SELECTION_WEB_VIEW_ROUTE,
  TAKEOFF_LEGENDS_EXPORT_ROUTE,
]

export const LANDING_ROUTES = [
  HOME_SCREEN_ROUTE,
  HOW_IT_WORKS_SCREEN_ROUTE,
  ABOUT_US_SCREEN_ROUTE,
  CONTACT_US_SCREEN_ROUTE,
  PRICING_SCREEN_ROUTE,
  TERMS_AND_CONDITIONS_SCREEN_ROUTE,
  PRIVACY_POLICY_SCREEN_ROUTE,
  COOKIE_POLICY_SCREEN_ROUTE,
  SCHEDULE_DEMO_ROUTE,
  TEAM_MANAGEMENT_SCREEN_ROUTE,
  PRE_CONSTRUCTION_SCREEN_ROUTE,
  PROJECT_MANAGEMENT_SCREEN_ROUTE,
  FINANCIAL_MANAGEMENT_SCREEN_ROUTE,
  RFI_TOOLS_SCREEN_ROUTE,
  ESTIMATION_SCREEN_ROUTE,
  PROPOSAL_SCREEN_ROUTE,
  SCHEDULING_SCREEN_ROUTE,
  BILLS_SCREEN_ROUTE,
  DOCUMENT_MANAGEMENT_SCREEN_ROUTE,
  CRM_SCREEN_ROUTE,
  INVOICES_SCREEN_ROUTE,
  BIDDING_SCREEN_ROUTE,
  LANDING_RESOURCES_ROUTE,
  TAKEOFF_SCREEN_ROUTE,
  CLIENT_PORTAL_SCREEN_ROUTE,
  TIMESHEET_SCREEN_ROUTE,
  SELECTIONS_SCREEN_ROUTE,
  ACCOUNTING_SCREEN_ROUTE,
  MOBILE_APP_SCREEN_ROUTE,
  BUDGET_SCREEN_ROUTE,
  COST_CATALOG_SCREEN_ROUTE,
  CHANGE_ORDER_SCREEN_ROUTE,
  DAILY_LOG_SCREEN_ROUTE,
  TO_DO_SCREEN_ROUTE,
  PURCHASE_ORDER_SCREEN_ROUTE,
  PRICE_LIST_SCREEN_ROUTE,
  WHATS_NEW_SCREEN_ROUTE,
  UPCOMING_FEATURES_SCREEN_ROUTE,
]

export const TAKEOFF_SCALING_ROUTE = '/scaling'
