/**
• This file contains helper functions that are primarily designed for use in landing pages.
• These functions can also be used in any part of the application.
• However, it is crucial to ensure that any additional functions added to this file
  do not have a negative impact on the overall size and performance of the landing pages.
*/

const requestIdleCallback = typeof window !== 'undefined' ? window.requestIdleCallback : null

export const waitForLoad = (check, callback) => {
  let elapsedTime = 0
  // If the provider fails to load we don't want to keep checking continuously
  // therefore we set a max duration we're willing to wait before executing the callback
  const maxWaitDuration = 10000
  // If the browser does not support requestIdleCallback we'll wait the fallback duration
  // before executing the callback
  const fallbackDuration = 1000

  const scheduleLoad = (deadline) => {
    if (check() || elapsedTime >= maxWaitDuration) {
      callback()
      return
    }

    elapsedTime += deadline.timeRemaining()
    requestIdleCallback?.(scheduleLoad)
  }

  if (requestIdleCallback) {
    requestIdleCallback(scheduleLoad)
  } else {
    setTimeout(callback, fallbackDuration)
  }
}
