import React from 'react'
import { Helmet } from 'react-helmet'

const HelpCrunch = () => {
  return (
    <Helmet>
      <script type="text/javascript" defer>
        {`
    window.helpcrunchSettings = {
      organization: 'buildern',
      appId: ${process.env.REACT_APP_HELPCRUNCH_ID},
    };
    ((w, d) => {
      const hS = w.helpcrunchSettings;
      if (!hS || !hS.organization) {
        return;
      }
      const widgetSrc = 'https://' + hS.organization + '.widget.helpcrunch.com/';
      w.HelpCrunch = function () {
        w.HelpCrunch.q.push(arguments);
      };
      w.HelpCrunch.q = [];
    
      const r = () => {
        if (d.querySelector('script[src="' + widgetSrc + '"]')) {
          return;
        }
        const s = d.createElement('script');
        s.defer = true;
        s.type = 'text/javascript';
        s.src = widgetSrc;
        (d.body || d.head).appendChild(s);
      };
      setTimeout(r, 8000); // Wrapped the HelpCrunch load function in setTimeout with a delay of 8000 milliseconds
    })(window, document);
  `}
      </script>
    </Helmet>
  )
}
export default HelpCrunch
