import React, { useEffect, useState } from 'react'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import LostConnection from './LostConnection'

window.addEventListener('online', () => {
  if (window.needToReloadPage) {
    window.location.reload()
  }
})

function FallbackComponent({ hasConnection }) {
  return hasConnection ? <div>An error has occurred</div> : <LostConnection />
}

const ErrorBoundary = (props) => {
  const { children } = props
  const [hasConnection, setHasConnection] = useState(true)

  useEffect(() => {
    const handleLostConnection = () => {
      setHasConnection(false)
    }

    window.addEventListener('offline', handleLostConnection)

    return () => window.removeEventListener('offline', handleLostConnection)
  }, [])

  return (
    <SentryErrorBoundary
      fallback={
        process.env.NODE_ENV === 'development' ? null : (
          <FallbackComponent hasConnection={hasConnection} />
        )
      }
    >
      {children}
    </SentryErrorBoundary>
  )
}

SentryErrorBoundary.displayName = 'SentryErrorBoundary'

export default ErrorBoundary
