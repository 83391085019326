import { createTheme } from '@material-ui/core/styles'
import {
  TEXTFIELD_COLOR,
  DARKEN_GRAY_COLOR,
  LIGHTEN_GRAY_COLOR,
  DARK_BLUE_COLOR,
} from 'constants/colors'

export const TEXTFIELD_FONT_SIZE = 16
export const TEXTFIELD_BORDER_COLOR = DARKEN_GRAY_COLOR

const FormControlTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        color: DARK_BLUE_COLOR,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: `${TEXTFIELD_FONT_SIZE}px !important`,
        lineHeight: '24px !important',
        color: TEXTFIELD_COLOR,
      },
      inputMarginDense: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    MuiFormLabel: {
      root: {
        color: TEXTFIELD_COLOR,
        fontSize: TEXTFIELD_FONT_SIZE,
        '&$focused': {
          color: TEXTFIELD_COLOR,
        },
        '&$disabled': {
          color: TEXTFIELD_COLOR,
          opacity: 0.4,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 11px) scale(1)',
        '&$marginDense': {
          transform: 'translate(14px, 11px) scale(1)',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 4,
        borderColor: TEXTFIELD_BORDER_COLOR,
        borderWidth: 1,
        '& legend': {
          fontSize: TEXTFIELD_FONT_SIZE - 4,
        },
      },
      input: {
        color: TEXTFIELD_COLOR,
        fontSize: `${TEXTFIELD_FONT_SIZE}px !important`,
        lineHeight: '24px !important',
        paddingTop: 8,
        paddingBottom: 8,
      },
      inputMarginDense: {
        paddingTop: 8,
        paddingBottom: 8,
      },
      root: {
        minHeight: 40,
        color: TEXTFIELD_COLOR,
        '& $notchedOutline': {
          borderColor: TEXTFIELD_BORDER_COLOR,
          borderWidth: 1,
        },
        '&:hover $notchedOutline': {
          borderColor: TEXTFIELD_BORDER_COLOR,
          borderWidth: 1,
        },
        '&$focused $notchedOutline': {
          borderColor: TEXTFIELD_BORDER_COLOR,
          borderWidth: 1,
        },
        '&$disabled $notchedOutline': {
          borderColor: TEXTFIELD_BORDER_COLOR,
          borderWidth: 1,
          opacity: 0.4,
          background: LIGHTEN_GRAY_COLOR,
          color: DARKEN_GRAY_COLOR,
        },
        '&$disabled svg': {
          opacity: 0.4,
          cursor: 'auto',
        },
      },
    },
  },
})

export default FormControlTheme
