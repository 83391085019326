export const DELETE_LOADING_TEXT = 'Deleting...'
export const SAVE_LOADING_TEXT = 'Saving...'
export const FETCH_LOADING_TEXT = 'Loading...'
export const UPDATE_LOADING_TEXT = 'Updating...'
export const UPLOAD_LOADING_TEXT = 'Uploading...'
export const IMPORT_LOADING_TEXT = 'Importing...'
export const SIGNING_UP_LOADING_TEXT = 'Signing up...'
export const RESTORE_LOADING_TEXT = 'Restoring...'
export const RESTORE_IMPORTING_TEXT = 'Importing...'
export const SENDING_CONTACT_INFO_TEXT = 'Sending contact info...'
export const DOWNLOAD_LOADING_TEXT = 'Downloading...'
export const SENDING_LOADING_TEXT = 'Sending...'
