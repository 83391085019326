import React, { lazy } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { LANDING_ROUTES } from 'constants/routes'
import { lazyLoader } from 'helpers/common/lazyLoad'

const Landing = lazy(() => lazyLoader(() => import('components/landing/Main')))
const ApolloReduxProvider = lazy(() =>
  lazyLoader(() => import('components/main/ApolloReduxProvider'))
)

const Root = () => {
  const location = useLocation()
  const history = useHistory()

  window.buildernHistoryPush = history.push

  if (LANDING_ROUTES.includes(location.pathname)) return <Landing />

  return <ApolloReduxProvider />
}

export default Root
