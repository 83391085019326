/**
• This file contains helper functions that are primarily designed for use in landing pages.
• These functions can also be used in any part of the application.
• However, it is crucial to ensure that any additional functions added to this file
  do not have a negative impact on the overall size and performance of the landing pages.
*/

export const lazyLoader = (lazyComponent) => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    )
    lazyComponent()
      .then((cmp) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
        resolve(cmp)
      })
      .catch((error) => {
        if (error.name !== 'ChunkLoadError') {
          return reject(error)
        }
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        return reject(error) // Default error behaviour as already tried refresh
      })
  })
}
